<template>
  <usage-by-month
    endpoint="/api/team-credits"
    prefix="Team/Members"
  />
</template>

<script>
// Import the common usage table component
import UsageByMonth from "@/components/admin/common/UsageByMonth.vue"

// Export the SFC
export default {
  // Name of the component
  name: "TeamAdminUserUsageByMonth",

  // Register the components
  components: {
    UsageByMonth
  }
}
</script>
