<template>
  <div class="large-page">
    <breadcrumbs>
      <div class="d-flex flex-wrap flex-grow-1 justify-end align-center">
        <v-btn
          depressed
          color="white"
          @click="fetchItems"
        >
          <v-icon color="secondary" left>
            refresh
          </v-icon>

          Refresh
        </v-btn>
      </div>
    </breadcrumbs>

    <template v-if="response">
      <v-data-table
        disable-pagination
        hide-default-footer
        class="mt-4 box-shadow-soft"
      >
        <template v-slot:header>
          <thead>
            <tr>
              <th>
                Name
              </th>

              <th>
                Email
              </th>

              <th>
                Balance
              </th>

              <th>
                Total Credits Added
              </th>

              <th class="text-end">
                Action
              </th>
            </tr>
          </thead>
        </template>

        <template v-slot:body>
          <tbody>
            <tr>
              <td>
                {{ response.user.name }}
              </td>

              <td>
                {{ response.user.email }}
              </td>

              <td>
                {{ Number(response.user.balance) / constants.conversionRate }}
              </td>

              <td>
                {{ lifetimeCredits / constants.conversionRate }}
              </td>

              <td class="text-end">
                <v-btn
                  small
                  depressed
                  color="primary"
                  @click="$router.push({ name: `${prefix}/Edit`, params: { userId: response.user.id } })"
                >
                  Edit
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>

      <!-- Show the select inputs -->
      <v-card
        flat
        class="px-4 py-6 mt-4"
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-select
              v-model="selectedType"
              :items="typeOptions"
              label="Type"
              hide-details
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-select
              v-model="selectedModule"
              :items="moduleOptions"
              label="Module"
              hide-details
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card>

      <v-data-table
        :headers="tableHeaders"
        :items="tableItems"
        class="mt-4 box-shadow-soft"
      >
        <template v-slot:item.amount="{ item }">
          <span
            :class="{ 'green--text': item.type === 'credits', 'red--text': item.type === 'debits' }"
          >
            <span>
              {{ item.type === "debits" ? "-" : "+" }}
            </span>
            {{ Number(item.amount) / constants.conversionRate }}
          </span>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
// Import the breadcrumb component for routes
const Breadcrumbs = () => import(/* webpackChunkName: "breadcrumbs" */ "@/components/common/Breadcrumbs.vue")

// Export the SFC
export default {
  // Name of the component
  name: "UsageByMonth",

  // Register the components
  components: {
    Breadcrumbs
  },

  // Accept incoming props from parent
  props: {
    // The network URL to make request to
    endpoint: String,
    // Toe prefix for routes
    prefix: String
  },

  // Define local data variables
  data: () => ({
    isLoading: true,
    response: null,

    selectedModule: null,
    selectedType: null,

    typeOptions: [
      {
        text: "All",
        value: null
      },
      {
        text: "Debits",
        value: "debits"
      },
      {
        text: "Credits",
        value: "credits"
      }
    ],

    tableHeaders: [
      {
        text: "Month",
        value: "month",
        sortable: true,
        sort: (a, b) => {
          return dayjs(a, "MMMM YYYY").unix() - dayjs(b, "MMMM YYYY").unix()
        }
      },
      {
        text: "Module",
        value: "module",
        sortable: false
      },
      {
        text: "Amount",
        value: "amount",
        sortable: true
      }
    ]
  }),

  // Define local computable properties
  computed: {
    /**
     * Get all the module options
     *
     * @returns {Array}
     */
    moduleOptions() {
      const items = []

      // Go through debits/credits
      for (const type in this.response.transactions) {
        // Go through each of the transaction key
        for (const key in this.response.transactions[type]) {
          // If there are items in this key
          if (this.response.transactions[type][key].length) {
            // Push the item in items
            items.push(key)
          }
        }
      }

      // Get the unique items
      const uniqueItems = [...new Set(items)]

      // Return the items
      return [
        {
          text: "All",
          value: null
        },
        ...(
          uniqueItems.map((item) => ({
            text: titleCaseString(item),
            value: item
          }))
        )
      ]
    },

    /**
     * Compute the table items
     *
     * @returns {Array}
     */
    tableItems() {
      const items = []

      // Loop through transaction types
      for (const type in this.response.transactions) {
        // If a type is selected and it's not this
        if (this.selectedType && this.selectedType !== type) {
          continue
        }

        // Loop through each of the transaction key
        for (const key in this.response.transactions[type]) {
          // If a module is selected and it's not this
          if (this.selectedModule && this.selectedModule !== key) {
            continue
          }

          // Loop through each of the transaction record
          for (const record of this.response.transactions[type][key]) {
            // Compute the date string from month and year
            const month = dayjs(`01-${String(record.month).padStart(2, "0")}-${record.year}`, "DD-MM-YYYY").format("MMMM YYYY")

            // Push the entire item in it
            items.push({
              type,
              month,
              timestamp: dayjs(month, "MMMM YYYY").unix(),
              module: titleCaseString(key),
              amount: parseInt(record.amount),
            })
          }
        }
      }

      return items.sort((a, b) => b.timestamp - a.timestamp)
    },

    /**
     * Compute the lifetime credits
     *
     * @returns {Number}
     */
    lifetimeCredits() {
      // The items to consider
      let items = []

      // If the credit_type is unified
      if (this.response.user.credit_type === "unified") {
        items = this.response.transactions.credits["unified-balance"]
      }
      // Otherwise
      else {
        items = [
          ...this.response.transactions.credits["influencer-discovery"],
          ...this.response.transactions.credits["influencer-insight"],
        ]
      }

      // Respond with sum of "amount"
      return items.reduce((acc, item) => acc + Number(item.amount), 0)
    }
  },

  // Define local method functions
  methods: {
    /**
     * Fetch the items from the API
     *
     * @returns {void}
     */
    async fetchItems() {
      // Show a loader
      this.isLoading = true
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        // Make a request to the API
        const response = await axios({
          url: `${this.endpoint}/${this.$route.params.userId}`,
        })

        // Set the response
        this.response = response.data
      } catch (error) {
        // Log the error
        logger({ type: "Credit Usage By Month Fetch Error", error })

        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      } finally {
        this.isLoading = false

        this.$store.dispatch("loaders/remove", loaderId)
      }
    }
  },

  /**
   * As soon as the component is ready
   *
   * @returns {void}
   */
  created() {
    // Fetch the items
    this.fetchItems()
  }
}
</script>
